import { headingStyles, buttonStyles, buttonHoverStyles } from './base'
import { light } from '@material-ui/core/styles/createPalette'

const theme = {
	config: {
		useCustomProperties: false,
		useColorSchemeMediaQuery: false,
	},
  breakpoints: ['375px', '576px', '768px', '992px', '1200px'],
  colors: {
    background: '#eff3f1',
    text: '#262626', // text-black
    black: '#000000',
    white: '#FFFFFF',
    whiteTwo: '#FBFBFB',
    primary: '#1B6F65', // viridian
    primaryHover: '#26A295',
    primaryInputBorder: '#009688',
    duckEggBlue: '#CBF1E6',
    inputPlaceholder: '#909F9E',
    blackBorder: '#979797',
    cornflowerBlue: '#5279e6',
    paleRed: '#ea4444',
    warning: '#a76612',
  },
  buttons: {
    primary: {
      ...buttonStyles,
      bg: 'primary',
      color: 'white',
      borderRadius: '4px',
      ':not(.Disabled):hover, :active': {
        color: 'white',
        bg: 'primaryHover',
        textDecoration: 'none',
        borderColor: 'primaryHover',
      },
    },
    secondary: {
      ...buttonStyles,
      ':hover, :active': {
        ...buttonHoverStyles,
      },
    },
    default: {
      bg: 'transparent',
      color: 'primary',
      fontFamily: 'body',
      border: 'none',
    },
    roundedPrimary: {
      ...buttonStyles,
      borderRadius: '24px',
      bg: 'primary',
      color: 'white',
      ':hover, :active': {
        color: 'white',
        bg: 'primaryHover',
        textDecoration: 'none',
        borderColor: 'primaryHover',
      },
    },
    navigation: {
      bg: 'transparent',
      color: 'text',
      minHeight: '26px',
      fontFamily: 'body',
      letterSpacing: '.034em', // Fix for hover spacing
      ':active, :hover, &.active': {
        color: 'text',
        letterSpacing: '0', // Fix for hover spacing
        fontWeight: 'bold',
        borderBottom: '2px',
        textDecoration: 'none',
        borderBottomColor: 'primary',
        borderBottomStyle: 'solid',
      },
    },
    outline: {
      bg: 'transparent',
      color: 'white',
      px: '77px',
      py: '13px',
      fontWeight: 'bold',
      borderRadius: '3px',
      border: 'solid 2px white',
      ':active, :hover': {
        color: 'primary',
        bg: 'white',
      },
    },
    disabled: {
      ...buttonStyles,
      opacity: 0.3,
    },
  },
  fonts: {
    body: "'Open Sans', system-ui, sans-serif",
    heading: "'Open Sans', system-ui, sans-serif",
  },
  fontSizes:
    // 0, 1, 2, 3,   4,  5,  6,  7,  8,  9,  10
    [16, 18, 20, 22, 25, 30, 32, 34, 39, 45, 60],
  space:
    // 0, 1, 2, 3,  4,  5,  6,  7,  8,  9,  10,  11,  12,  13,  14
    [0, 5, 10, 15, 20, 25, 30, 40, 60, 80, 100, 120, 150, 180, 210],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    dark: 600,
    light: 300,
    normal: 400,
    display: 300,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.33,
    paragraph: 1.38,
  },
  sizes: {
    xs: 340,
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
  },
  zIndices: {
    hero: 2,
    heroText: 3,
    header: 3,
    collapsibleNavigation: 4,
    fixedCTA: 5,
  },
  borderWidths: [1, 2, 3, 5],
  styles: {
    h1: {
      ...headingStyles,
      color: 'primary',
      fontSize: [4, 5, 5, 9],
    },
    h2: {
      ...headingStyles,
      color: 'primary',
      fontSize: [3, 4, 4, 8],
    },
    h3: {
      ...headingStyles,
      fontSize: [2, 3, 3, 7],
    },
    h4: {
      ...headingStyles,
      fontSize: [1, 2, 2, 4],
    },
    p: {
      m: 0,
      fontSize: 0,
      fontFamily: 'body',
      lineHeight: 'paragraph',
    },
    a: {
      color: 'primary',
      fontFamily: 'body',
      lineHeight: 'paragraph',
      textDecoration: 'underline',
      ':hover, :active': {
        color: 'primary',
      },
      primary: {
        ':hover, :active': {
          color: 'primary',
        },
      },
      white: {
        color: 'white',
        ':hover, :active': {
          color: 'white',
        },
      },
    },
  },
}

export default theme

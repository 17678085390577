/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
import 'typeface-open-sans'
import 'typeface-montserrat'
import theme from './src/constants/theme'
import { ThemeProvider } from '@emotion/react'
import { AuthProvider } from './src/contexts/authentication-context'

import { TextEncoder } from 'text-encoding'

/* global window */
if (
  typeof window !== 'undefined' &&
  typeof window.TextEncoder === 'undefined'
) {
  window.TextEncoder = TextEncoder
  if (typeof window.msCrypto !== 'undefined') {
    window.crypto = window.msCrypto
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </AuthProvider>
  )
}

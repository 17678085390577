import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  FC,
} from 'react'

import { navigate } from 'gatsby'
import { config, EnvVariable } from 'utils/config'

import Axios, { AxiosInstance } from 'axios'

const AuthContext = createContext<Partial<any>>({})

const axiosInstance: AxiosInstance = Axios.create({
  timeout: 7000,
  headers: {
    'Content-Type': 'application/json'
  },
})

/**
 * Main auth details provider
 * TODO: orest - add type definitions
 * @see https://github.com/okta/okta-auth-js for available options
 * @param props
 */
const AuthProvider: FC = ({ children }) => {
  const auth = () => {}

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

function useAuth() {
  const context = useContext(AuthContext)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  if (!context) {
    throw new Error('useAuth has to be used inside Auth.Provider')
  }

  useEffect(() => {
    const t = new Date();
    axiosInstance
    .get<any>(`/environment.json?t=${t.getTime()}`, {})
    .then((response) => {
      const env = (window as any).env = response.data || {};
      if (env.user) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
        // window.location.href = '/auth/okta-oidc';
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      }
    })
    .catch(err => {
      throw err;
    });
    /*
    context.tokenManager!.get('idToken').then((token: any) => {
      if (token) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
        navigate('/')
      }
    })
    */
  }, [])

  return {
    auth: context,
    isAuthenticated,
  }
}

export { AuthContext as default, useAuth, AuthProvider }

import { BaseSxStyleProp } from "theme-ui"

export const headingStyles: BaseSxStyleProp = {
  m: 0,
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
}

export const absoluteStyles: BaseSxStyleProp = {
  top: "0",
  left: "0",
  position: "absolute",
}

export const fixedStyles: BaseSxStyleProp = {
  ...absoluteStyles,
  position: "fixed",
}

export const imageStyles: BaseSxStyleProp = {
  maxWidth: "100%",
  height: "auto",
}

// Secondary Button
export const buttonStyles: BaseSxStyleProp = {
  py: 2,
  px: 2,
  bg: "white",
  color: "text",
  fontSize: "16px",
  fontFamily: "body",
  borderWidth: "2px",
  borderRadius: "3px",
  textAlign: "center",
  borderStyle: "solid",
  borderColor: "primary",
  cursor: "pointer"
}

export const buttonHoverStyles: BaseSxStyleProp = {
  color: "text",
  borderWidth: "2px",
  bg: "duckEggBlue",
  textDecoration: "none",
}
